var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "support" },
    [
      _c("AppTopbar", {
        attrs: {
          title: "Support",
          buttonText: "New Order",
          buttonIconClass: "icon-add",
          buttonLink: "new"
        }
      }),
      _c("div", { staticClass: "container-fluid pt-70 pb-50 pt-md-100" }, [
        _c("div", { staticClass: "card col-md-8 m-auto" }, [
          _vm.seller.hasOwnProperty("accountManager") &&
          _vm.seller.accountManagerEmail
            ? _c("div", { staticClass: "hasaccountManager" }, [
                _vm.seller.hasOwnProperty("firstEscalationManager") &&
                _vm.seller.firstEscalationManagerEmail
                  ? _c("div", [
                      _vm._m(0),
                      _c("div", { staticClass: "card__content pt-13 ml-20" }, [
                        _c("div", [
                          _c("div", { staticClass: "col-md-12 mb-10 pt-10" }, [
                            _c("div", { staticClass: "fs14 text-gray-600" }, [
                              _vm._v(" Name : "),
                              _c("strong", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.seller.firstEscalationManagerName
                                        ? _vm.seller.firstEscalationManagerName
                                        : "N/A"
                                    ) +
                                    " "
                                )
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "col-md-12 mb-10 pt-7" }, [
                            _c("div", { staticClass: "fs14 text-gray-600" }, [
                              _vm._v(" Email : "),
                              _c("strong", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.seller.firstEscalationManagerEmail
                                        ? _vm.seller.firstEscalationManagerEmail
                                        : "N/A"
                                    ) +
                                    " "
                                )
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "col-md-12 mb-10 pt-7" }, [
                            _c("div", { staticClass: "fs14 text-gray-600" }, [
                              _vm._v(" Phone : "),
                              _c("strong", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.seller.firstEscalationManagerPhone
                                        ? _vm.seller.firstEscalationManagerPhone
                                        : "N/A"
                                    ) +
                                    " "
                                )
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "card__title",
                    class: {
                      "pt-20": !(
                        _vm.seller.hasOwnProperty("firstEscalationManager") &&
                        _vm.seller.firstEscalationManagerEmail
                      )
                    }
                  },
                  [
                    _c("span", { staticClass: "fs16 fw500 d-block" }, [
                      _vm._v(" Account Manager ")
                    ])
                  ]
                ),
                _c("div", { staticClass: "card__content pt-13 ml-20" }, [
                  _c("div", [
                    _c("div", { staticClass: "col-md-12 mb-10 pt-10" }, [
                      _c("div", { staticClass: "fs14 text-gray-600" }, [
                        _vm._v(" Name : "),
                        _c("strong", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.seller.accountManagerName
                                  ? _vm.seller.accountManagerName
                                  : "N/A"
                              ) +
                              " "
                          )
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-12 mb-10 pt-7" }, [
                      _c("div", { staticClass: "fs14 text-gray-600" }, [
                        _vm._v(" Email : "),
                        _c("strong", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.seller.accountManagerEmail
                                  ? _vm.seller.accountManagerEmail
                                  : "N/A"
                              ) +
                              " "
                          )
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-12 mb-10 pt-7" }, [
                      _c("div", { staticClass: "fs14 text-gray-600" }, [
                        _vm._v(" Phone : "),
                        _c("strong", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.seller.accountManagerPhone
                                  ? _vm.seller.accountManagerPhone
                                  : "N/A"
                              ) +
                              " "
                          )
                        ])
                      ])
                    ]),
                    _vm._m(1)
                  ])
                ]),
                _vm._m(2),
                _c("div", { staticClass: "card__content pt-13 ml-20" }, [
                  _c("div", [
                    _c("div", { staticClass: "col-md-12 mb-10 pt-10" }, [
                      _c(
                        "div",
                        { staticClass: "fs14 text-gray-600" },
                        [
                          _vm._v(" FAQ : "),
                          _c(
                            "b-link",
                            {
                              staticClass: "fs14",
                              attrs: {
                                href: "https://faq.shyplite.com",
                                target: "__blank"
                              }
                            },
                            [_c("strong", [_vm._v(" faq.shyplite.com")])]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ])
            : _c("div", [
                _vm._m(3),
                _c("div", { staticClass: "card__content pt-13 ml-20" }, [
                  _c("div", [
                    _c("div", { staticClass: "col-md-12 mb-10 pt-10" }, [
                      _vm.seller.salesAssigned
                        ? _c(
                            "div",
                            { staticClass: "fs14 text-gray-600" },
                            [
                              _vm._v(" Email : "),
                              _c(
                                "b-link",
                                {
                                  staticClass: "fs14",
                                  attrs: {
                                    href: "mailto:info@shyplite.com",
                                    target: "__blank"
                                  }
                                },
                                [_c("strong", [_vm._v(" info@shyplite.com")])]
                              )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "fs14 text-gray-600" },
                            [
                              _vm._v(" Email : "),
                              _c(
                                "b-link",
                                {
                                  staticClass: "fs14",
                                  attrs: {
                                    href: "mailto:support@shyplite.com",
                                    target: "__blank"
                                  }
                                },
                                [
                                  _c("strong", [
                                    _vm._v(" support@shyplite.com")
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                    ])
                  ])
                ]),
                _vm._m(4),
                _c("div", { staticClass: "card__content pt-13 ml-20" }, [
                  _c("div", [
                    _c("div", { staticClass: "col-md-12 mb-10 pt-10" }, [
                      _c(
                        "div",
                        { staticClass: "fs14 text-gray-600" },
                        [
                          _vm._v(" FAQ : "),
                          _c(
                            "b-link",
                            {
                              staticClass: "fs14",
                              attrs: {
                                href: "https://faq.shyplite.com",
                                target: "__blank"
                              }
                            },
                            [_c("strong", [_vm._v(" faq.shyplite.com")])]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ]),
                _vm._m(5),
                _c("div", { staticClass: "card__content pt-13 ml-20" }, [
                  _c("div", [
                    _vm.seller.salesAssigned
                      ? _c("div", { staticClass: "col-md-12 mb-10 pt-10" }, [
                          _c("div", { staticClass: "fs14 text-gray-600" }, [
                            _vm._v(" Your Sales Manager: "),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.seller.salesManager))
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _vm.seller.salesAssigned
                      ? _c("div", { staticClass: "col-md-12 mb-10 pt-10" }, [
                          _c("div", { staticClass: "fs14 text-gray-600" }, [
                            _vm._v(" Contact Sales: "),
                            _c("strong", { staticClass: "fs14" }, [
                              _vm._v(_vm._s(_vm.salesContact))
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _vm.seller.salesAssigned === false
                      ? _c("div", { staticClass: "col-md-12 mb-10 pt-10" }, [
                          _c(
                            "div",
                            { staticClass: "fs14 text-gray-600" },
                            [
                              _vm._v(" Contact: "),
                              _c(
                                "b-link",
                                {
                                  staticClass: "fs14",
                                  attrs: { href: "tel:+919643318580" }
                                },
                                [_c("strong", [_vm._v(" +91-9643318580")])]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._m(6)
                  ])
                ])
              ]),
          !_vm.seller.salesAssigned
            ? _c("div", [
                _vm._m(7),
                _c("div", { staticClass: "card__content row pt-13 ml-20" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-10 text-gray-600",
                          attrs: {
                            label: "Contact Number",
                            "label-for": "amount"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "amount",
                              type: "number",
                              formatter: _vm.maxten,
                              required: "",
                              placeholder: "888 888 8888",
                              state: _vm.isNumber
                            },
                            on: {
                              keyup: function($event) {
                                return _vm.validation($event.target.value)
                              }
                            },
                            model: {
                              value: _vm.contact,
                              callback: function($$v) {
                                _vm.contact = $$v
                              },
                              expression: "contact"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 text-right" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: !_vm.enableCall || _vm.submitting,
                            variant: "primary mt-35"
                          },
                          on: { click: _vm.callSupport }
                        },
                        [
                          _vm.submitting
                            ? _c("b-spinner")
                            : _c("span", [_vm._v("Call Back")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e()
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card__title" }, [
      _c("span", { staticClass: "fs16 fw500 d-block" }, [
        _vm._v(" Support Manager ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12 mb-10 pt-7" }, [
      _c("div", { staticClass: "fs14 text-gray-600" }, [
        _c("strong", [
          _vm._v(
            " Our working hours are 10 AM to 7 PM from Monday to Saturday. "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card__title pt-0" }, [
      _c("span", { staticClass: "fs16 fw500 d-block" }, [_vm._v(" FAQ ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card__title" }, [
      _c("span", { staticClass: "fs16 fw500 d-block" }, [_vm._v(" Email Us ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card__title pt-0" }, [
      _c("span", { staticClass: "fs16 fw500 d-block" }, [_vm._v(" FAQ ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card__title pt-0" }, [
      _c("span", { staticClass: "fs16 fw500 d-block" }, [_vm._v(" Call Us ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12 mb-10 pt-7" }, [
      _c("div", { staticClass: "fs14 text-gray-600" }, [
        _c("strong", [
          _vm._v(
            " Our working hours are 10 AM to 7 PM from Monday to Saturday. "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card__title pt-0" }, [
      _c("span", { staticClass: "fs16 fw500 d-block" }, [
        _vm._v(" Request a call back ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }