<template>
	<div class="support">
		<AppTopbar
		title="Support"
		buttonText="New Order"
		buttonIconClass="icon-add"
		buttonLink="new"
		/>

		<div class="container-fluid pt-70 pb-50 pt-md-100">
			<div class="card col-md-8 m-auto">
				<div class="hasaccountManager" v-if="seller.hasOwnProperty('accountManager') && seller.accountManagerEmail ">

					<div v-if="seller.hasOwnProperty('firstEscalationManager') && seller.firstEscalationManagerEmail">
						<div class="card__title">
							<span class="fs16 fw500 d-block">
								Support Manager 
							</span>
						</div>
						<div class="card__content pt-13 ml-20">
							<div>
								<div class="col-md-12 mb-10 pt-10">
									<div class="fs14 text-gray-600">
										Name : <strong> {{seller.firstEscalationManagerName ? seller.firstEscalationManagerName : 'N/A'}} </strong>
									</div>
								</div>
								<div class="col-md-12 mb-10 pt-7">
									<div class="fs14 text-gray-600">
										Email : <strong> {{seller.firstEscalationManagerEmail ? seller.firstEscalationManagerEmail : 'N/A'}} </strong>
									</div>
								</div>
								<div class="col-md-12 mb-10 pt-7">
									<div class="fs14 text-gray-600">
										Phone : <strong> {{seller.firstEscalationManagerPhone ? seller.firstEscalationManagerPhone : 'N/A'}} </strong>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="card__title" :class="{ 'pt-20' : !(seller.hasOwnProperty('firstEscalationManager') && seller.firstEscalationManagerEmail) }">
						<span class="fs16 fw500 d-block">
							Account Manager
						</span>
					</div>
					<div class="card__content pt-13 ml-20">
						<div>
							<div class="col-md-12 mb-10 pt-10">
								<div class="fs14 text-gray-600">
									Name : <strong> {{seller.accountManagerName ? seller.accountManagerName : 'N/A'}} </strong>
								</div>
							</div>
							<div class="col-md-12 mb-10 pt-7">
								<div class="fs14 text-gray-600">
									Email : <strong> {{seller.accountManagerEmail ? seller.accountManagerEmail : 'N/A'}} </strong>
								</div>
							</div>
							<div class="col-md-12 mb-10 pt-7">
								<div class="fs14 text-gray-600">
									Phone : <strong> {{seller.accountManagerPhone ? seller.accountManagerPhone : 'N/A'}} </strong>
								</div>
							</div>
							<div class="col-md-12 mb-10 pt-7">
								<div class="fs14 text-gray-600">
									<strong> Our working hours are 10 AM to 7 PM from Monday to Saturday. </strong>
								</div>
							</div>
						</div>
					</div>

					<div class="card__title pt-0">
						<span class="fs16 fw500 d-block">
							FAQ
						</span>
					</div>
					<div class="card__content pt-13 ml-20">
						<div>
							<div class="col-md-12 mb-10 pt-10">
								<div class="fs14 text-gray-600">
									FAQ : <b-link href="https://faq.shyplite.com" class="fs14" target="__blank"><strong> faq.shyplite.com</strong></b-link>
								</div>
							</div>

						</div>
					</div>
				</div>

				<!-- No Account Manager and Email -->

				<div v-else>
					<div class="card__title">
						<span class="fs16 fw500 d-block">
							Email Us
						</span>
					</div>
					<div class="card__content pt-13 ml-20">
						<div>
							<div class="col-md-12 mb-10 pt-10">
								<div v-if="seller.salesAssigned" class="fs14 text-gray-600">
									Email : <b-link href="mailto:info@shyplite.com" class="fs14" target="__blank"><strong> info@shyplite.com</strong></b-link>
								</div>
								<div v-else class="fs14 text-gray-600">
									Email : <b-link href="mailto:support@shyplite.com" class="fs14" target="__blank"><strong> support@shyplite.com</strong></b-link>
								</div>
							</div>

						</div>
					</div>

					<div class="card__title pt-0">
						<span class="fs16 fw500 d-block">
							FAQ
						</span>
					</div>
					<div class="card__content pt-13 ml-20">
						<div>
							<div class="col-md-12 mb-10 pt-10">
								<div class="fs14 text-gray-600">
									FAQ : <b-link href="https://faq.shyplite.com" class="fs14" target="__blank"><strong> faq.shyplite.com</strong></b-link>
								</div>
							</div>

						</div>
					</div>


					<div class="card__title pt-0">
						<span class="fs16 fw500 d-block">
							Call Us
						</span>
					</div>
					<div class="card__content pt-13 ml-20">
						<div>
							<div v-if="seller.salesAssigned" class="col-md-12 mb-10 pt-10">
								<div class="fs14 text-gray-600">
									Your Sales Manager: <strong>{{ seller.salesManager }}</strong>	
								</div>
							</div>
							<div v-if="seller.salesAssigned" class="col-md-12 mb-10 pt-10">
								<div class="fs14 text-gray-600">
									Contact Sales: <strong class="fs14">{{salesContact}}</strong>
								</div>
							</div>
							<div v-if="seller.salesAssigned === false" class="col-md-12 mb-10 pt-10">
								<div class="fs14 text-gray-600">
									Contact: <b-link href="tel:+919643318580" class="fs14"><strong> +91-9643318580</strong></b-link>
								</div>
							</div>
							<div class="col-md-12 mb-10 pt-7">
								<div class="fs14 text-gray-600">
									<strong> Our working hours are 10 AM to 7 PM from Monday to Saturday. </strong>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div v-if="!seller.salesAssigned">
					<div class="card__title pt-0">
						<span class="fs16 fw500 d-block">
							Request a call back
						</span>
					</div>
					<div class="card__content row pt-13 ml-20">
						<div class="col-md-6">
							<b-form-group class="mb-10 text-gray-600" label="Contact Number" label-for="amount">
								<b-form-input
								id="amount"
								type="number"
								:formatter="maxten"
								required
								@keyup="validation($event.target.value)"
								placeholder="888 888 8888"
								v-model="contact"
								:state="isNumber"
								></b-form-input>
							</b-form-group>
						</div>
						<div class="col-md-6 text-right">
							<b-button @click="callSupport" :disabled="!enableCall || submitting" variant="primary mt-35">
								<b-spinner v-if="submitting"></b-spinner>
								<span v-else>Call Back</span>
							</b-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import AppTopbar from "@/components/layout/AppTopbar.vue";


export default {
	name: "Support",
	title: "Shyplite - Support",
	data() {
		return {
			orderType: null,
			options: ["Prepaid", "Cod", "Reverse"],
			contact: null,
			seller: {},
			enableCall: false,
			isNumber: undefined,
			salesContact: '',
			salesManager: false,
			submitting: false
		};
	},
	components: {
		AppTopbar
	},
	methods:{
		validation(val) {
			if(val || val =='') {
				if(val.match(/^[6-9]{1}[0-9]{9}$/)){
					this.isNumber = true
					this.enableCall = true
				} else if (val == '') {
					this.isNumber = undefined
					this.enableCall = false
				} else{
					this.isNumber = false
					this.enableCall = false
				}
			}

		},
		maxten(e){
			return String(e).substring(0, 10);
		},
		async getsupportData() {
			try {
				const res = await this.axios.get('/support')
				this.seller = res.data.seller
				this.salesManager = res.data.salesManager
				this.salesContact = res.data.salesContact
			} catch(e) {
				console.log(e);
			}

		},
		async callSupport() {
			this.submitting = true
			try {
				const res = await this.axios.post('support/callme',{contact:this.contact})
				console.log(res)
				if (res.data.message) {
					this.popToast("booked", "Success!!", res.data.message);
					this.isNumber = undefined
					this.contact = ""
					this.submitting = false
					this.enableCall = false
				} else {
					this.popToast("failed", "Failed!!", "Please Try again later");
					this.submitting = false
					this.enableCall = false
				}

			} catch(e) {
				this.popToast("failed", "Failed!!", "Please Try again later");
				this.submitting = false
				this.enableCall = false
				console.log(e);
			}
		}
	},
	mounted() {
		this.getsupportData();
	}
};
</script>


<style scoped>

/deep/ #__BVID__69__BV_label_ {
	margin-left: 0.1rem !important
}

</style>